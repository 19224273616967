import React from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo-alb.png"
import footerMap from "../../assets/images/footer-map.png"
import "../../assets/css/Footer/footer.style.css"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-color">

      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img src={logo} alt="logo" loading="lazy" />
              </a>

              <ul className="footer-links-list">
                <li>
                  <a href="mailto:contact@sitebunker.net">
                    contact@sitebunker.net
                  </a>
                </li>
                {/*<li>*/}
                {/*  <a href="tel:0377101459">0377 101 459</a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <p>*/}
                {/*    <strong>Phone Schedule:</strong>*/}
                {/*    <br/> Monday-Friday 9:00-17:00<br/> except for public holidays*/}
                {/*  </p>*/}
                {/*</li>*/}
                <li>
                  <p>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/sitebunker.net"
                    >
                      <i className="bx bxl-facebook"/> Facebook
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Premium Hosting</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/web-ssd-hosting/">Web SSD Hosting</Link>
                </li>
                <li>
                  <Link to="/wordpress-ssd-hosting/">
                    WordPress SSD Hosting
                  </Link>
                </li>
                <li>
                  <Link to="/prestashop-ssd-hosting/">
                    PrestaShop SSD Hosting
                  </Link>
                </li>
                <li>
                  <Link to="/magento-ssd-hosting/">Magento SSD Hosting</Link>
                </li>
                <li>
                  <Link to="/reseller-ssd-hosting/">Reseller SSD Hosting</Link>
                </li>
              </ul>
            </div>

            <div className="single-footer-widget pl-5">
              <h3>Domains</h3>

              <ul className="footer-links-list">
                <li>
                  <a
                    href="https://client.sitebunker.net/cart.php?a=add&domain=register&language=english&currency=3"
                    target="_blank"
                  >
                    Domain Registration
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Servers</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/dedicated-servers/">Dedicated Servers</Link>
                </li>
                <li>
                  <Link to="/vps-servers/">VPS Servers</Link>
                </li>
              </ul>
            </div>

            <div className="single-footer-widget pl-5">
              <h3>Useful Links</h3>
              <ul className="footer-links-list">
                <li>
                  <Link to="/privacy-policy/">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-service/">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/sla-quality-agreement/">
                    SLA - Quality Agreement
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            {/*<div className="single-footer-widget pl-5">*/}
            {/*  <h3>Licențe</h3>*/}

            {/*  <ul className="footer-links-list">*/}
            {/*    <li>*/}
            {/*      <Link to="#">Licența cPanel/WHM</Link>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <Link to="#">Licența CloudLinux</Link>*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}

            <div className="single-footer-widget pl-5">
              <h3>Info</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/affiliates/">Affiliate</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/achizitii-seap">Achizitii SEAP</Link>*/}
                {/*</li>*/}
                <li>
                  <a
                    href="https://client.sitebunker.net/submitticket.php?language=english&currency=3"
                    target="_blank"
                  >
                    Support
                  </a>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="single-footer-widget pl-5">
              <p className="small d-flex flex-row flex-wrap"><span>* Prices do not include VAT.</span>
                <span>The 14 day money back guarantee does not apply to domains</span>
                <span>1 GB = 1000 MB</span>
              </p>
            </div>
          </div>


        </div>

        <div  className="footer-bottom-area col-12">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>

                Copyright ©{currentYear} Sitebunker.net
              </p>

            </div>
          </div>

        </div>
      </div>

      <div  className="footer-map">
        <img src={footerMap} alt="footer-logo" loading="lazy"  />
      </div>

{/*      <script*/}
{/*          dangerouslySetInnerHTML={{*/}
{/*            __html: `*/}
{/*!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);*/}
{/*},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',*/}
{/*a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');*/}
{/*twq('config','odu2v');*/}
{/*    `,*/}
{/*          }}*/}
{/*      />*/}
    </footer>
  )
}

export default Footer
