import React, {useState} from "react"
import {useRecoilState} from "recoil"
import {collapsedState} from "../../utils/recoil-atoms"
import {Link} from "gatsby"
import logo from "../../assets/images/logo-sitebunker.png"
import "../../i18n"
import ReactTooltip from "react-tooltip"
import EmailIcon from "../../assets/svgComponents/email-icon.svg"

const Navbar = () => {

    const [systemStatus, setSystemStatus] = useState('on');
    const [collapsed, setCollapsed] = useRecoilState(collapsedState)
    const [isActive, setActive] = useState({active: false, id: "", hidden: true})

    const handleToggle = (id) => {
        if (window.innerWidth < 992) {
            let element = document.getElementById(id)
            let elementHidden = element.classList.contains("hideDropdown")
            setActive({active: elementHidden, id: id, hidden: elementHidden})
        } else {
        }
    }

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
    }

    // Add sticky navbar
    const addStickyNav = () => {

        let elementId = document.getElementById("navbar")
        let topBarId = document.getElementById("top-bar")
        let bodyEl = document.getElementById("gatsby-focus-wrapper")

        if (window.scrollY > 170) {
            elementId.classList.add("is-sticky")
            topBarId.classList.add("is-sticky")
            bodyEl.classList.add("mt-sticky")

        } else {
            elementId.classList.remove("is-sticky")
            topBarId.classList.remove("is-sticky")
            bodyEl.classList.remove("mt-sticky")
        }
    }

    React.useEffect(() => {
        fetch("https://sitebunker-app.herokuapp.com/system-status")
            .then(res => res.json())
            .then(
                (result) => {
                    setSystemStatus(result["SystemStatusSelect"]);
                })



        // Sticky nav
        addStickyNav()

        // On scroll add sticky class
        document.addEventListener("scroll", () => {
            addStickyNav()
        })


        let element1Link = document.getElementById("domainsLink")
        let element2Link = document.getElementById("hostingLink")
        // let element3Link = document.getElementById("aboutLink")
        let element4Link = document.getElementById("serversLink")
        let element5Link = document.getElementById("resellerLink")


        document.addEventListener("click", function (event) {
            if (window.innerWidth < 992) {
                var isClickInside1 = element1Link.contains(event.target)
                var isClickInside2 = element2Link.contains(event.target)
                // var isClickInside3 = element3Link.contains(event.target)
                var isClickInside4 = element4Link.contains(event.target)
                var isClickInside5 = element5Link.contains(event.target)


                let element1 = document.getElementById("domainsDropdown")
                let element2 = document.getElementById("hostingDropdown")
                // let element3 = document.getElementById("aboutDropdown")
                let element4 = document.getElementById("serversDropdown")
                let element5 = document.getElementById("resellerDropdown")

                if (isClickInside1) {
                    element1.classList.remove("hideDropdown")
                    element1.classList.add("showDropdown")
                }

                if (isClickInside2) {
                    element2.classList.remove("hideDropdown")
                    element2.classList.add("showDropdown")
                }

                // if (isClickInside3) {
                //   element3.classList.remove("hideDropdown")
                //   element3.classList.add("showDropdown")
                // }

                if (isClickInside4) {
                    element4.classList.remove("hideDropdown")
                    element4.classList.add("showDropdown")
                }

                if (isClickInside5) {
                    element5.classList.remove("hideDropdown")
                    element5.classList.add("showDropdown")
                }

                if (!isClickInside1) {
                    element1.classList.remove("showDropdown")
                    element1.classList.add("hideDropdown")
                }

                if (!isClickInside2) {
                    element2.classList.remove("showDropdown")
                    element2.classList.add("hideDropdown")
                }

                // if (!isClickInside3) {
                //   element3.classList.remove("showDropdown")
                //   element3.classList.add("hideDropdown")
                // }

                if (!isClickInside4) {
                    element4.classList.remove("showDropdown")
                    element4.classList.add("hideDropdown")
                }

                if (!isClickInside5) {
                    element5.classList.remove("showDropdown")
                    element5.classList.add("hideDropdown")
                }
            }
        })
    }, [])


    const classOne = collapsed
        ? "collapse navbar-collapse"
        : "collapse navbar-collapse show"
    const classTwo = collapsed
        ? "navbar-toggler navbar-toggler-right collapsed"
        : "navbar-toggler navbar-toggler-right"

    return (
        <React.Fragment>
            <div id="top-section">
                <div id="top-bar" className="container-fluid">
                    <div className="top-items">

                        {systemStatus === 'on' ?
                            <Link to="/status/" className="mr-5 report-functional d-flex align-items-center top-item"><i
                                className="bx bx-check-circle mr-2"/> All systems are operational</Link> :
                            systemStatus === 'off' ?
                                <Link to="/status/"
                                      className="mr-5 report-incident d-flex align-items-center top-item"><i
                                    className="bx bx-error mr-2"/> Reported incident</Link> :
                                systemStatus === 'maintenance' ?
                                    <Link to="/status/"
                                          className="mr-5 report-maintenance d-flex align-items-center top-item"><i
                                        className="bx bx-loader mr-2"/> Scheduled maintenance</Link> : null
                        }
                        <div className="top-item">
                            <EmailIcon className="mr-2"/>
                            <a href="mailto:contact@sitebunker.net">contact@sitebunker.net</a> | Non-Stop Program
                        </div>

                    </div>

                </div>
                <div id="navbar" className="navbar-area">
                    <div className="tarn-nav">
                        <div className="container-fluid">

                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link
                                    to="/"
                                    onClick={() => setCollapsed(true)}
                                    className="navbar-brand"
                                >
                                    <img src={logo} alt="logo" loading="lazy"/>
                                </Link>
                                <div className="mobile-user-button">
                                    <a href="https://client.sitebunker.net/clientarea.php?language=english&currency=3"
                                       target="_blank">
                                        <i className="bx bxs-user-circle"/>
                                    </a>
                                </div>
                                <button
                                    onClick={toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="icon-bar top-bar"/>
                                    <span className="icon-bar middle-bar"/>
                                    <span className="icon-bar bottom-bar"/>
                                </button>

                                <div className={classOne} id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a onClick={() => handleToggle("domainsDropdown")}
                                               className="nav-link initial-cursor"
                                               id="domainsLink"
                                            >
                                                Domains <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "domainsDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="domainsDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/register-domains/"
                                                        className="nav-link"
                                                        target="_blank"
                                                    >
                                                        Domain Registration
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="/transfer-domains/"
                                                        className="nav-link"
                                                        target="_blank">Domain Transfer</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/web-ssd-hosting/"
                                                activeClassName="active"
                                                className="nav-link"
                                            >
                                                Web SSD Hosting
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link initial-cursor"
                                               onClick={() => handleToggle("hostingDropdown")}
                                               id="hostingLink"
                                            >
                                                Premium Hosting <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "hostingDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="hostingDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/wordpress-ssd-hosting/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        WordPress SSD Hosting
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/prestashop-ssd-hosting/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        PrestaShop SSD Hosting
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/magento-ssd-hosting/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Magento SSD Hosting
                                                    </Link>
                                                </li>

                                                {/*<li className="nav-item">*/}
                                                {/*    <Link*/}
                                                {/*        to="/enefti-ssd-hosting/"*/}
                                                {/*        activeClassName="active"*/}
                                                {/*        onClick={() => setCollapsed(true)}*/}
                                                {/*        className="nav-link"*/}
                                                {/*    >*/}
                                                {/*        Enefti SSD Hosting*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}

                                                {/*<li className="nav-item">*/}
                                                {/*    <a*/}
                                                {/*        href="https://client.sitebunker.net/cart.php?a=add&pid=128&language=english&currency=3"*/}
                                                {/*        target="_blank"*/}
                                                {/*        onClick={() => setCollapsed(true)}*/}
                                                {/*        className="nav-link"*/}
                                                {/*    >*/}
                                                {/*        Personalized Hosting*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}

                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link initial-cursor"
                                               onClick={() => handleToggle("serversDropdown")}
                                               id="serversLink"
                                            >
                                                Servers <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "serversDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="serversDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/vps-servers/"
                                                        activeClassName="active"
                                                        className="nav-link"
                                                    >
                                                        VPS Servers
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/dedicated-servers/"
                                                        activeClassName="active"
                                                        className="nav-link"
                                                    >
                                                        Dedicated Servers
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="/collocation-servers/"
                                                        activeClassName="active"
                                                        className="nav-link"
                                                    >
                                                        Collocation Servers
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link initial-cursor"
                                               onClick={() => handleToggle("resellerDropdown")}
                                               id="resellerLink"
                                            >
                                                Reseller <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "resellerDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="resellerDropdown">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/reseller-ssd-hosting/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Reseller SSD Hosting
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>


                                        <li className="nav-item">
                                            <Link
                                                to="/affiliates/"
                                                activeClassName="active"
                                                onClick={() => setCollapsed(true)}
                                                className="nav-link"
                                            >
                                                Affiliates
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <a onClick={() => handleToggle("aboutUsLinkDropdown")}
                                               className="nav-link initial-cursor"
                                               id="aboutUsLink"
                                            >
                                                About Us <i className="bx bx-chevron-down"/>
                                            </a>

                                            <ul
                                                className={`dropdown-menu ${(isActive.active && isActive.id === "aboutUsLinkDropdown" && isActive.hidden) ? "showDropdown" : "hideDropdown"}`}
                                                id="aboutUsLinkDropdown">

                                                <li className="nav-item">
                                                    <Link
                                                        to="/blog/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Blog
                                                    </Link>
                                                </li>
                                                {/*<li className="nav-item">*/}
                                                {/*    <Link*/}
                                                {/*        to="/partners/"*/}
                                                {/*        activeClassName="active"*/}
                                                {/*        onClick={() => setCollapsed(true)}*/}
                                                {/*        className="nav-link"*/}
                                                {/*    >*/}
                                                {/*        Partners*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                                <li className="nav-item">
                                                    <Link
                                                        to="/contact/"
                                                        activeClassName="active"
                                                        onClick={() => setCollapsed(true)}
                                                        className="nav-link"
                                                    >
                                                        Contact
                                                    </Link>
                                                </li>


                                            </ul>
                                        </li>
                                    </ul>

                                    <div className="others-option d-flex align-items-center">
                                        <div className="service-status mr-3">
                                            <Link
                                                to="/status/"
                                                className="nav-link heartbeat"
                                                data-for="services-status"
                                                data-tip="Services Status"
                                                data-text-color="white"
                                                data-background-color="#17992C"
                                                data-place="bottom"
                                                data-effect="solid"
                                            >
                                                <i className="bx bxs-server"/>
                                            </Link>
                                            <ReactTooltip className="services-status-tooltip" id="services-status"/>
                                        </div>
                                        {/*<div className="language-switcher mr-3">*/}
                                        {/*  <a href="https://sitebunker.ro" target='_blank'>*/}
                                        {/*    <img src={roFlag} loading="lazy" />*/}
                                        {/*  </a>*/}
                                        {/*</div>*/}
                                        <div className="user-button mr-3">
                                            <a className="my-account"
                                               href="https://client.sitebunker.net/clientarea.php?language=english&currency=3"
                                               target="_blank">
                                                <i className="bx bxs-user-circle"/>
                                                <span>My account</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Navbar
