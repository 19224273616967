import React from "react"
import { RecoilRoot } from "recoil"
import GoTop from "./GoTop"
import CookieConsent from "react-cookie-consent"
import {LiveChatWidget} from "@livechat/widget-react";

const Layout = ({ children }) => {
  return (
    <RecoilRoot>
      {children}
      <GoTop scrollStepInPx="100" delayInMs="0" />
        <LiveChatWidget license="14692527" />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{ background: "#000", zIndex: "999999999999" }}
        buttonStyle={{ background: "#21A8F8", color: "#fff", fontSize: "15px", fontWeight: "700", marginTop: "5px" }}
        contentStyle={{ paddingRight: "10px", paddingLeft: "10px" }}
        expires={30}
        overlay={false}

      >
        Sitebunker.net uses cookies to improve your browsing experience and ensure functionality. Please agree to the <a
        href="/terms-of-service"><strong>terms and conditions</strong></a> and the <a
        href="/privacy-policy"><strong>privacy policy</strong></a> to continue.
      </CookieConsent>
    </RecoilRoot>
  )
}

export default Layout
